import React, {useState, useEffect} from 'react';
import'./Management.scss';
import SingletoneStoreClass from "../../../Store/Store";
import TextViewer from "../../../helpers/textViewer";
import utils from "../../../helpers/utils";
import {useHistory} from "react-router-dom";
import Main from './Main/Main'
import PrayersDetails from "./PrayersDetails/PrayersDetails";
import AliyahPanels from "./AliyahPanels/AliyahPanels";
import Donations from "./Donations/Donations";
import Times from "./Times/Times";
import Updates from "./Updates/Updates";
import PlasmaSetting from "./Plasma/plasma";
import DvarTora from "./DvarTora/dvarTora";
import SynagogueDetails from "./SynagogueDetails/synagogueDetails";
import Reports from "./Reports/reports";
import ReportsV2 from "./Reports/ReportsV2";
import Loading from "../Main/Loading/Loading";
import {Modal} from "antd";
import phone_logo from "../icons/phone-colored.svg";
import whatsapp_logo from "../icons/whatsapp-colored.svg";
import config from '../../../config';

const store = SingletoneStoreClass.getInstance();


const Management = (props)=>{

    const synagogueName = utils.getSynagogueName();
    const [path, setPath] = useState('');
    const [loading, setLoading] = useState(true);
    const [synagogueFullData, setSynagogueFullData] = useState({});

    const history = useHistory();


    useEffect(() => {
        document.documentElement.style.setProperty('--edit-is-on-disable-none', 'none');
        document.documentElement.style.setProperty('--edit-is-on-background-color', '#C2EAFF');


        const unlisten = history.listen( location =>  {
            if(location.hash.startsWith('#')) setPath(location.hash.split('#')[1])
            else setPath(location.pathname.split('/').pop())
        });

        if(window.location.href.includes('#')) setPath(window.location.href.split('#')[1]);


        const fetchData = async ()=>{
            setLoading(true);
            let data = await store.getSynagogueFullData();
            setSynagogueFullData(data);
            console.log("-----data=", data);
            setLoading(false);
        };

        fetchData();

        return ()=>{
            document.documentElement.style.setProperty('--edit-is-on-disable-none', 'flex');
            document.documentElement.style.setProperty('--edit-is-on-background-color', '#fff');

            return unlisten;
        }
    }, []);

    const updateData = async ({fieldName, data})=>{
        setLoading(true);
        if(Array.isArray(data)){
            data.forEach(item=>{
                delete item.index;
            });
        }
        const updatedData = await store.changeSynagogueField(fieldName, data);
        setSynagogueFullData(updatedData.data);
        console.log("-----updatedData=", updatedData);
        setLoading(false);
    }

    const updateExtraDetails = async ({fieldName, data})=>{
        const newExtraDetails = {...synagogueFullData.extraDetails};
        newExtraDetails[fieldName] = data;
        return updateData({fieldName: 'extraDetails', data: newExtraDetails});
    }

    const updateAllExtraDetails = async ({data})=>{
        return updateData({fieldName: 'extraDetails', data});
    }

    const renderMain = ()=> <Main times={synagogueFullData.times || []} users={synagogueFullData.manager || []} base64Logo={synagogueFullData.extraDetails?.base64Logo} synagogueName={synagogueFullData.name} location={synagogueFullData.location} updates={synagogueFullData.extraDetails?.updates}/>




    const renderComponent = ()=>{
        if(loading) return <Loading/>
        if(!path) return renderMain();
        if(path === 'main') return renderMain();
        if(path === 'prayers_details') return <PrayersDetails loading={loading} users={synagogueFullData.manager} updateData={updateData} base64Logo={synagogueFullData.extraDetails?.base64Logo} synagogueName={synagogueFullData.name}/>
        if(path === 'aliyah_panel') return <AliyahPanels loading={loading} users={synagogueFullData.manager} updateData={updateData} base64Logo={synagogueFullData.extraDetails?.base64Logo} synagogueName={synagogueFullData.name}/>
        if(path === 'times') return <Times loading={loading} users={synagogueFullData.manager} times={synagogueFullData.times || []} updateData={updateData}/>
        if(path === 'messages_and_notifications') return <Updates loading={loading} updates={synagogueFullData.extraDetails?.updates} updateData={updateExtraDetails}/>
        if(path === 'donations') return <Donations loading={loading} users={synagogueFullData.manager} updateData={updateData} base64Logo={synagogueFullData.extraDetails?.base64Logo} synagogueName={synagogueFullData.name}/>
        if(path === 'plasma_setting') return <PlasmaSetting loading={loading} plasmaSetting={synagogueFullData.extraDetails?.plasmaSetting} updateData={updateExtraDetails}/>
        if(path === 'dvar_tora') return <DvarTora loading={loading} dvarTora={synagogueFullData.dvarTora} tvarToraYoutubeLink={synagogueFullData.extraDetails?.tvarToraYoutubeLink} updateData={updateData} updateDataExtraDetails={updateExtraDetails}/>
        if(path === 'synagogue_details') return <SynagogueDetails loading={loading} cityName={synagogueFullData.cityName} location={synagogueFullData.location} name={synagogueFullData.name} info={synagogueFullData.info} donation={synagogueFullData.donation} extraDetails={synagogueFullData.extraDetails} updateData={updateData} updateAllExtraDetails={updateAllExtraDetails}/>
        if(path === 'reports') return <Reports loading={loading} users={synagogueFullData.manager} updateData={updateData} base64Logo={synagogueFullData.extraDetails?.base64Logo} synagogueName={synagogueFullData.name} location={synagogueFullData.location}/>
        if(path === 'reportsv2') return <ReportsV2 loading={loading} users={synagogueFullData.manager} updateData={updateData} base64Logo={synagogueFullData.extraDetails?.base64Logo} synagogueName={synagogueFullData.name} location={synagogueFullData.location} times={synagogueFullData.times || []} extraDetails={synagogueFullData.extraDetails}/>

        return renderMain();
    }

    const renderPaymentModal = ()=>{
        if(synagogueFullData.disabled){
            return <Modal className={'payment-modal'}
                          visible={true} footer={null} maskClosable={false} closable={false} keyboard={false}>
                <div className={'payment-content'}>
                    <TextViewer text={'trial_period_over'} addChar={'.'} className={'payment-modal-title'}/>

                    <div className={'communications-actions'}>
                        <div className={'payment-button'}>
                            <a href={`tel:${config.feedbackPhone}`} ><img src={phone_logo} alt="icon"/></a>
                        </div>
                        <TextViewer text={'or'}/>
                        <div className={'payment-button'} onClick={()=>{
                            utils.sendWhatsAppMessage({msg:`  היי אור - צד גבאי שלי חסום` + `(${synagogueFullData.name})`, phoneNumber: config.feedbackPhone})
                        }}>
                            <img src={whatsapp_logo} alt="icon"/>
                        </div>
                    </div>

                    <p>--- My Synagogue ---</p>
                </div>

            </Modal>
        }
        return null;

    }


    return <div className={'management-container'}>
        <div className={'beta-version'} onClick={utils.feedbackManagementV2}>
            <span>גרסה ראשונית. יש לך פידבק ? אשמח שתלחץ <a>כאן</a></span>
        </div>
        {renderComponent()}
        {renderPaymentModal()}
    </div>
}

export default Management;