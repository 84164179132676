import moment from "moment";
import utils from "../../helpers/utils"
import SingletoneStoreClass from "../../Store/Store";
import HebrewZmanim from '../../Store/hebrewZmanim';

import React from "react";
const store = SingletoneStoreClass.getInstance();

const TFILOT = {
    shaharit: "שחרית",
    minha: "מנחה",
    arvit: "ערבית",
    kabalatShabat: "קבלת שבת"
};
const { getDateBy_HHMM } = utils;

const DAY_IN_MS = 86400000;

const _timeToDate = ({location, dayNum, tfilaName, time, isRoshChodesh, useYeshivaTimes, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})=>{
    //todo: use this for render lesson/tfila time obj!
    let date;
    if(time && time.disable){
        return null;
    }
    if(!time) return null;
    const roshChodeshMode = isRoshChodesh && time?.roshHodeshMinutes;
    if (typeof time === "object") {
        //this condition needs to be removed after moving to v2
        if (typeof time.time === "string") {
            date = getDateBy_HHMM(time.time, roshChodeshMode ? -time.roshHodeshMinutes : 0);
            return { name: TFILOT[tfilaName] || tfilaName, date };
        }

        if (typeof time.when === "string" && !time.isDynamic) {
            date = getDateBy_HHMM(time.when, roshChodeshMode ? -time.roshHodeshMinutes : 0);
            return { name: TFILOT[tfilaName] || tfilaName, date };
        }

        if(time.dynamic || time.relativeTimeBy){
            const { maxPerWeek, minPerWeek } = time;

            const dynamicTime = utils.calcDynamicTime({
                type: time.dynamic || time.relativeTimeBy,
                time: time,
                location,
                dayNum,
                useYeshivaTimes,
                maxPerWeek,
                minPerWeek,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            if(roshChodeshMode && dynamicTime){
                date = moment(dynamicTime).add(-time.roshHodeshMinutes, 'm').toDate();
                return { name: TFILOT[tfilaName] || tfilaName, date };
            }else{
                if(dynamicTime){
                    date = dynamicTime;
                    return {name: TFILOT[tfilaName] || tfilaName, date};
                }
            }
        }

    } if (typeof time === "string") { //backward compatibility for tfila times of string (not object)
        date = getDateBy_HHMM(time, roshChodeshMode ? -time.roshHodeshMinutes : 0);
        return { name: TFILOT[tfilaName] || tfilaName, date };
    }
}

const TIME_CACHE = {};

const tfilotParser = {
    timeObjV2ToDate: ({time})=>{
        if(TIME_CACHE[JSON.stringify(time)]) return TIME_CACHE[JSON.stringify(time)];
        const {add, days, dynamic, isDynamic, maxPerWeek, minPerWeek, roshHodeshMinutes, when} = time;
        if(!isDynamic){
            TIME_CACHE[JSON.stringify(time)] = getDateBy_HHMM(when);
            return getDateBy_HHMM(when);
        }
        const responseDate =  utils.calcDynamicTime({
            type: dynamic,
            time: time,
            location: null,
            dayNum: new Date().getDay(),
            useYeshivaTimes : null,
            maxPerWeek,
            minPerWeek
        });
        TIME_CACHE[JSON.stringify(time)] = responseDate;
        return responseDate;

    },
    calcTimesOfDayV2: ({date, location, monthName, dayOfMonth})=>{
        const hebrewZmanim = new HebrewZmanim({date, location});

        const events =  [
            {name: 'sunrise', date: hebrewZmanim.getSunrise()},
            {name: 'sunset', date: hebrewZmanim.getSunset()},
            {name: 'tzeit', date: hebrewZmanim.getStarsCameOut()},
            {name: 'alot_hashacher', date: hebrewZmanim.getAlosHashachar()},
            {name: 'plag_hamincha', date: hebrewZmanim.getPlagHamincha()},
            {name: 'sof_zman_shma', date: hebrewZmanim.getSofZmanShma()}
        ]

        if(monthName === 'Nisan' && dayOfMonth === 14){
            events.push({
            name: 'sof_zman_achilas_chametz_baal_hatanya', date: hebrewZmanim.getSofZmanAchilasChametzBaalHatanya(),
            })
            events.push({
                name: 'sof_zman_achilas_chametz_GRA', date: hebrewZmanim.getSofZmanAchilasChametzGRA(),
            })
            events.push({
                name: 'sof_zman_biur_chametz_GRA', date: hebrewZmanim.getSofZmanBiurChametzGRA(),
            })
            events.push({
                name: 'sof_zman_biur_chametz_baal_hatanya', date: hebrewZmanim.getSofZmanBiurChametzBaalHatanya(),
            })
        }

        return events;

        //todo: add more events + translate to english
    },
    calcLessonsV2: ({lessonsTimes, date, location, useYeshivaTimes, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})=>{
        const dayNum = date.getDay();
        const isRoshChodesh = store.isRoshChodesh(date);

        const results = [];
        lessonsTimes[Number(dayNum)].forEach(lessonObj=>{
            const {by, details, link, name, time} = lessonObj;
            let dateEvent = _timeToDate({
                location,
                dayNum,
                tfilaName: name,
                time,
                isRoshChodesh,
                useYeshivaTimes,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            if(dateEvent) results.push({...dateEvent, description: details, by, zoomLink: link, where: lessonObj.location });
        })
        return utils.sortTimes(results);
    },
    calcTfilotTimesV2: ({tfilotTimes, holidayTimes = {}, holidayTimesMetadata, date, location, useYeshivaTimes, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset})=>{
        const deepCopyOfTfilotTimes = JSON.parse(JSON.stringify(tfilotTimes));
        const deepCopyOfHolidayTimes = JSON.parse(JSON.stringify(holidayTimes));
        const isRoshChodesh = store.isRoshChodesh(date);
        let results = [];
        const dayNum = date.getDay();

        let tfilotOfTheDay;

        if(holidayTimesMetadata && holidayTimesMetadata.startDate && holidayTimesMetadata.endDate){
            if(date.getTime() > holidayTimesMetadata.startDate && date.getTime() < holidayTimesMetadata.endDate){
                console.info(`${date} is on the range of the holiday! using holidayTimes.`);
                tfilotOfTheDay = deepCopyOfHolidayTimes[Number(dayNum)];
            }else{
                tfilotOfTheDay = deepCopyOfTfilotTimes[Number(dayNum)];
            }
        }else {
            tfilotOfTheDay = deepCopyOfTfilotTimes[Number(dayNum)];
        }

        Object.keys(tfilotOfTheDay).forEach(tfilaName => {
            let times = tfilotOfTheDay[tfilaName];
            times.forEach(time=>{
                let dateEvent = _timeToDate({
                    location,
                    dayNum,
                    tfilaName,
                    time,
                    isRoshChodesh,
                    useYeshivaTimes,
                    shabatExitMinAfterStarsCameOut,
                    shabatEnterMinBeforeSunset
                });
                if(dateEvent) results.push(dateEvent);
            });
            results = results.filter(t=>t);
        })

        return utils.sortTimes(results);
    },
    calcTimesV2: ({times, date, location, useYeshivaTimes, types=['tfila', 'lesson'], minyanName, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset}) =>{
        if(!times) return [];
        if(!Array.isArray(times)) return [];
        if(times.length === 0) return [];
        const isRoshChodesh = store.isRoshChodesh(date);
        const deepCopyOfTimes = JSON.parse(JSON.stringify(times));
        const DAY_NUM_TO_ENG = {
            0: 'sunday',
            1: 'monday',
            2: 'tuesday',
            3: 'wednesday',
            4: 'thursday',
            5: 'friday',
            6: 'saturday'
        };
        const { monthName, dayOfMonth } = store.getHebrewDayAndMouthAndYear({date, location});
        const requiredDayNum = date.getDay();

        const holidayTimesOfTheDay = deepCopyOfTimes.filter(time=>{
            if(minyanName){
                if(time.minyan !== minyanName) return false;
            }
            if(time.importantDates && !time.disable){
                if(monthName === time.importantDates.hebrewMonth){
                    if(dayOfMonth === time.importantDates.hebrewDayAtMonth) return true;
                    if(Array.isArray(time.importantDates.hebrewDayAtMonth) && time.importantDates.hebrewDayAtMonth.includes(dayOfMonth)) return true
                }
            }
        });
        const regularTimesOfTheDay = deepCopyOfTimes.filter(time=> {
            if(minyanName){
                if(time.minyan !== minyanName) return false;
            }
            if (time.days && Array.isArray(time.days) && time.days.includes(DAY_NUM_TO_ENG[requiredDayNum])) return true;
        });

        let timesOfTheDay;

        if(holidayTimesOfTheDay.length > 0){
            if(['Nisan','Tishrei'].includes(monthName) && [16,17,18,19,20].includes(dayOfMonth) && [6,5].includes(requiredDayNum)){
                // שבת חול המועד
                timesOfTheDay = regularTimesOfTheDay;
            }else{
                timesOfTheDay = holidayTimesOfTheDay;
            }
        }else{
            timesOfTheDay = regularTimesOfTheDay;
        }

        let results = [];
        timesOfTheDay.forEach(time=>{
            if(time.type){
                if(!types.includes(time.type)) return;
            }
            let dateEvent = _timeToDate({
                location,
                dayNum: requiredDayNum,
                tfilaName: time.name,
                time,
                isRoshChodesh,
                useYeshivaTimes,
                shabatExitMinAfterStarsCameOut,
                shabatEnterMinBeforeSunset
            });
            if(dateEvent) results.push({...dateEvent, description: time.extraDetails, minyan: time.minyan, zoomLink: time.zoomLink, where: time.location });
        });
        return results;
    }
}

export default tfilotParser;