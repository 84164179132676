import React, {useEffect, useState} from 'react';
import './BottomLinks.scss';
import Icon from '@ant-design/icons';
import {ReactComponent as feedback_logo} from "../../icons/feedback.svg";
import TextViewer from "../../../../helpers/textViewer";
import utils from "../../../../helpers/utils";
import {ReactComponent as home_logo} from "../../icons/home.svg";
import {ReactComponent as info_logo} from "../../icons/info.svg";
// import {ReactComponent as sidur_logo} from "../../icons/sidur.svg";
import {ReactComponent as setting_logo} from "../../icons/setting.svg";
import {ReactComponent as whatsapp_logo} from "../../icons/whatsapp.svg";
import tfilotParser from "../../../../helpers/TfilotOfTheDay/tfilotParser";

import {Link} from "react-router-dom";
import SingletoneStoreClass from "../../../../Store/Store";
import { useLocation } from 'react-router-dom'
import config from "../../../../config";
import {analyticsAddLogEvent} from "../../../../firebase/firebase";


const store = SingletoneStoreClass.getInstance();

const BottomLinks = (props)=>{

    const [location, setLocation] = useState({});

    const routeLocation = useLocation();


    const getPagePath = ()=>{
        return routeLocation?.pathname;
    }


    const shareSynagogueWeeklyTimes = async ()=>{

        analyticsAddLogEvent({eventName: 'share_synagogue_whatsapp', params: { name: utils.getSynagogueName()}});


        let { name, location, extraDetails, times } = (await store.getSynagoguePublicValues({keys: ['name', 'location', 'cityName', 'extraDetails', 'times']}));
        const {shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, useYeshivaTimes} = extraDetails;

        const nextTimeObj = utils.calcNextTimes({
            nextFollowDays: 10,
            returnFirstItem: true,
            shabatEnterMinBeforeSunset, shabatExitMinAfterStarsCameOut, useYeshivaTimes, location})


        // console.log("nextTimeObj:",nextTimeObj)


        const { parasha, shabbatEnter, shabbatExit, fast } = nextTimeObj[0];

        const firstDate = nextTimeObj[0].date;

        const fullDateAsString = store.getHebrewDate({location, date: firstDate});


        let data = await store.getSynagoguePublicValues({keys: ['extraDetails']});

        const updates = data?.extraDetails?.updates;



        let msg = '';
        msg+= '*';
        msg+= '🕍';
        msg+= name;
        msg+= '🕍';
        msg+= '*';
        msg+= '%0A';
        msg+= '%0A';
        msg+= 'לו״ז תפילות ל';

        if(!fast){
            msg+= 'פרשת';
        }
        msg+= ' ';
        msg+= '*';
        msg+= parasha;
        msg+= '*';
        msg+= '%0A';
        msg+= 'תאריך: ';
        msg+= fullDateAsString;
        msg+= '%0A';
        msg+= '%0A';
        msg+= '`';
        msg+= TextViewer({text: fast ? 'start_fast' : 'candle_lighting', returnString: true, addChar: ': '});
        msg+= utils.dateToHH_MM(shabbatEnter);
        msg+= '`';

        msg+= '%0A';
        msg+= '`';
        msg+= TextViewer({text: fast ? 'end_fast' : 'havdalah', returnString: true, addChar: ': '});
        msg+= utils.dateToHH_MM(shabbatExit);
        msg+= '`';



        const events = utils.sortTimes(tfilotParser.calcTimesV2({times, date: firstDate, location, useYeshivaTimes, types:['tfila', 'lesson'], minyanName: null, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset}));

        // console.log("events:",events);

        msg+= '%0A';
        msg+= '%0A';
        msg+= '*';
        msg+= `${config.days[firstDate.getDay()]}`;
        msg+= '*';
        msg+= '%0A';


        events.forEach(event=>{
            const {name, date, description, minyan, where, zoomLink} = event;
            msg+= '- ';
            msg+= utils.dateToHH_MM(date);
            msg+= ' - ';
            msg+= name;
            msg+= '%0A';
        })

        const nextDay = new Date(firstDate);
        nextDay.setDate(nextDay.getDate()+1);


        const events2 = utils.sortTimes(tfilotParser.calcTimesV2({times, date: nextDay, location, useYeshivaTimes, types:['tfila', 'lesson'], minyanName: null, shabatExitMinAfterStarsCameOut, shabatEnterMinBeforeSunset}));

        msg+= '%0A';
        msg+= '*';
        msg+= `${config.days[nextDay.getDay()]}`;
        msg+= '*';
        msg+= '%0A';


        events2.forEach(event=>{
            const {name, date, description, minyan, where, zoomLink} = event;
            msg+= '- ';
            msg+= utils.dateToHH_MM(date);
            msg+= ' - ';
            msg+= name;
            msg+= '%0A';
        })

        msg+= '%0A';
        msg+= '%0A';


        const relevantUpdates= utils.filterUpdates({updates});

        if(relevantUpdates.length > 0) {
            relevantUpdates.forEach(eventUpdate=>{
                let addedUpdate = false;
                if (eventUpdate.title) {
                    msg += `*${eventUpdate.title}*`;
                    addedUpdate = true;
                }
                if (eventUpdate.text) {
                    if(eventUpdate.title) {
                        msg += ': ';
                    }
                    msg += eventUpdate.text;
                    addedUpdate = true;
                }
                if(addedUpdate) msg+= '%0A';

            })

        }

        msg+= '%0A';
        msg+= '%0A';




        if(!fast){
            msg+= '✡️';
            msg+= 'שבת שלום';
            msg+= '✡️';
        }



        msg+= '%0A';
        msg+= '%0A';

        msg+= 'לפרטים נוספים, בקרו באפליקציה שלנו: https://mysynagogue.net';
        msg+= `/${utils.getSynagogueName()}`;




        // console.log(msg)


        window.open( `https://api.whatsapp.com/send?text=${msg}`, '_blank');
    }

    useEffect(() => {
        const fetchData = async ()=>{
            let { location } = await store.getSynagoguePublicValues({keys: ['location']});
            setLocation(location);
        };
        fetchData();
    }, []);

    const synagogueName = utils.getSynagogueName();

    if(!synagogueName) return null;

    return <React.Fragment>
        <div className={'empty-button-div'}></div>
        <div className={'bottom-links-container'}>
            <Link to={`/${synagogueName}`}>
                <div className={`bottom-link ${getPagePath().split('/').length === 2 ? 'clicked' : ''}`}>
                    <Icon component={home_logo} className={'bottom-link-icon'}/>
                    <TextViewer text={"home"} className={'bottom-link-text'}/>
                </div>
            </Link>
            <Link to={`/${synagogueName}/edit`}>
                <div className={`bottom-link ${getPagePath().split('/').pop() === 'edit' ? 'clicked' : ''}`}>
                    <Icon component={setting_logo} className={'bottom-link-icon'}/>
                    <TextViewer text={"gabbai_area"} className={'bottom-link-text'}/>
                </div>
            </Link>

            {/*<div className={'bottom-link'}>*/}
            {/*    <Icon component={sidur_logo} className={'bottom-link-icon'}/>*/}
            {/*    <TextViewer text={"siddur"} className={'bottom-link-text'}/>*/}
            {/*</div>*/}

            <Link to={`/${synagogueName}/info_v2`}>
                <div className={`bottom-link ${getPagePath().split('/').pop() === 'info_v2' ? 'clicked' : ''}`}>
                    <Icon component={info_logo} className={'bottom-link-icon'}/>
                    <TextViewer text={"about"} className={'bottom-link-text'}/>
                </div>
            </Link>
            <div className={'bottom-link'} onClick={shareSynagogueWeeklyTimes}>
                <Icon component={whatsapp_logo} className={'bottom-link-icon'}/>
                <TextViewer text={"share_times"} className={'bottom-link-text'}/>
            </div>
        </div>
    </React.Fragment>
}


export default BottomLinks;