// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent, setUserProperties, setUserId  } from "firebase/analytics";


import getSynagogueName from '../helpers/utils';
import utils from "../helpers/utils";

// import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC7MzsEXh38qZQjOxSchQTsvsnClXxMoh8",
    authDomain: "synagogue-app-e0a45.firebaseapp.com",
    databaseURL: "https://synagogue-app-e0a45.firebaseio.com",
    projectId: "synagogue-app",
    storageBucket: "synagogue-app.appspot.com",
    messagingSenderId: "887010059006",
    appId: "1:887010059006:web:02b42329bfaa2ad0d27baf",
    measurementId: "G-87RDGVTKE7"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(firebaseApp);

const isLocalHost = window.location.href.includes('localhost');

export function analyticsAddLogEvent({eventName, params}){
    if(isLocalHost) return;
     logEvent(analytics, eventName, params);
}

export function analyticsSetUserProperties(){
    if(isLocalHost) return;
    const synagogueName =  utils.getSynagogueName();
    setUserProperties(analytics, { synagogueName });
}

export function analyticsSetUserId({id}){
    if(isLocalHost) return;
    setUserId(analytics, id);
}

export async function uploadFile({file, name}){
    const fileRef = ref(storage, `attachments/${name}`);

    const result = await uploadBytes(fileRef, file);

    let url = await getDownloadURL(result.ref);

    return url;
}


//todo: set analytics events:
// const analytics = getAnalytics(app);
// export function createAnalyticsEvent({eventName, eventParams}){
//     logEvent(analytics, eventName, eventParams);
// }

export async function isPushNotificationSupported(){
    return await isSupported();
}

export function getClientToken(setTokenFound) {
    return getToken(messaging, {vapidKey: 'BOuWclrKa0hklfbK8wdOsEwr4ugVh3uOy8eWTq8Su1UjUsI0PA0MUj475Kzvk4M1DTKiF8Nrta1nUpOzu2xYbTw'}).then((currentToken) => {
        if (currentToken) {

            console.log('current token for client: ', currentToken);
            localStorage.setItem("fcmToken", currentToken);

            utils.updateUserAction(true);

            if(setTokenFound) setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            if(setTokenFound) setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}



