import React, {useEffect, useState} from 'react';
import './Main.scss';
import SingletoneStoreClass from "../../../Store/Store";
import NextShbbat from './NextShbbat/NextShbbat';
import SmartNotifications from './SmartNotifications/SmartNotifications';
import Calander from './Calander/Calendar';
import FloatButtonDonation from './Donation/FloatButtonDonation/FloatButtonDonation';
import NewsAndUpdatesSlider from './NewsAndUpdatesSlider/NewsAndUpdatesSlider';
import DvarTora from './DvarTora/DvarTora';
import TextViewer from "../../../helpers/textViewer";
import UnionOfSynagoguesLinkButton from './UnionOfSynagogues/UnionOfSynagoguesLinkButton/UnionOfSynagoguesLinkButton';
import BottomButtons from './BottomButtons/BottomButtons';
import Plasma from './Plasma/Plasma';
import BottomLinks from './BottomLinks/BottomLinks';
import Loading from './Loading/Loading';
import utils from "../../../helpers/utils";
import { analyticsAddLogEvent, analyticsSetUserProperties } from '../../../../src/firebase/firebase';
import SignUp from "../SignUp/SignUp";
import {useHistory} from "react-router-dom";
import FirstOnBoarding from "./FirstOnBoarding/FirstOnBoarding";
import SpecialMessages from "./SpecialMessages/SpecialMessages";
import DailyStudies from "./DailyStudies/DailyStudies";

const store = SingletoneStoreClass.getInstance();
const AUTO_UPDATE_DURATION = 1000 * 60 * 60// every 1 hour
let UPDATE_USER_STATS = true;

const Main = (props)=>{
    const {menuOpen} = props;

    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const [donationObj, setDonationObj] = useState(null);
    const [useYeshivaTimes, setUseYeshivaTimes] = useState(true);
    const [updates, setUpdates] = useState('');
    const [externalFormDetails, setExternalFormDetails] = useState('');
    const [externalFormUrl, setExternalFormUrl] = useState('');
    const [tvarToraYoutubeLink, SetTvarToraYoutubeLink] = useState('');
    const [dvarTora, setDvarTora] = useState('');
    const [plasmaMode, setPlasmaMode] = useState(false);
    const [base64Logo, setBase64Logo] = useState(null);
    const [name, setName] = useState(null);
    const [shabatEnterMinBeforeSunset, setShabatEnterMinBeforeSunset] = useState(null);
    const [shabatExitMinAfterStarsCameOut, setShabatExitMinAfterStarsCameOut] = useState(null);
    const [plasmaSetting, setPlasmaSetting] = useState({});
    const [times, setTimes] = useState([]);
    const [prayersHebrewDatesEvents, setPrayersHebrewDatesEvents] = useState([]);


    const history = useHistory();


    useEffect(() => {
        //auto refresh app metadata
        const interval = setInterval(() => {
            const fetchData = async ()=>{
                setLoading(true);
                const [publicValues, hebrewDatesList] = await Promise.all([store.getSynagoguePublicValues({keys: ['name', 'location', 'extraDetails', 'donation', 'updates', 'dvarTora', 'times'], ignoreCache: true }), getHebrewDatesList()]);
                let { name, location, extraDetails, donation, dvarTora, times } = publicValues;
                setLocation(location);
                setUseYeshivaTimes(extraDetails.useYeshivaTimes);
                setExternalFormDetails(extraDetails.externalFormDetails);
                setExternalFormUrl(extraDetails.externalFormUrl);
                SetTvarToraYoutubeLink(extraDetails.tvarToraYoutubeLink);
                setBase64Logo(extraDetails.base64Logo);
                setPlasmaSetting(extraDetails.plasmaSetting);
                setDvarTora(dvarTora);
                setDonationObj(donation);
                setUpdates(extraDetails.updates);
                setShabatEnterMinBeforeSunset(extraDetails.shabatEnterMinBeforeSunset);
                setShabatExitMinAfterStarsCameOut(extraDetails.shabatExitMinAfterStarsCameOut);
                setName(name);
                setTimes(times);
                setPrayersHebrewDatesEvents(hebrewDatesList);
                setLoading(false);
            };
            fetchData().then(()=>{
                if(UPDATE_USER_STATS){
                    utils.updateUserAction();
                    UPDATE_USER_STATS = false;
                }
            });
        }, AUTO_UPDATE_DURATION);
        return () => {
            clearInterval(interval);
        };
    }, []);


    useEffect(() => {
        window.innerWidth >= 768 ? setPlasmaMode(true) : setPlasmaMode(false);
        const fetchData = async ()=>{
            setLoading(true);
            const [publicValues, hebrewDatesList] = await Promise.all([store.getSynagoguePublicValues({keys: ['name', 'location', 'extraDetails', 'donation', 'updates', 'dvarTora', 'times'], ignoreCache: true }), getHebrewDatesList()]);
            let { name, location, extraDetails, donation, dvarTora, times } = publicValues;
            setLocation(location);
            setUseYeshivaTimes(extraDetails.useYeshivaTimes);
            setExternalFormDetails(extraDetails.externalFormDetails);
            setExternalFormUrl(extraDetails.externalFormUrl);
            SetTvarToraYoutubeLink(extraDetails.tvarToraYoutubeLink);
            setBase64Logo(extraDetails.base64Logo);
            setPlasmaSetting(extraDetails.plasmaSetting);
            setDvarTora(dvarTora);
            setDonationObj(donation);
            setUpdates(extraDetails.updates);
            setShabatEnterMinBeforeSunset(extraDetails.shabatEnterMinBeforeSunset);
            setShabatExitMinAfterStarsCameOut(extraDetails.shabatExitMinAfterStarsCameOut);
            setName(name);
            setTimes(times);
            setPrayersHebrewDatesEvents(hebrewDatesList);
            setLoading(false);
            analyticsAddLogEvent({eventName: 'main_app_fetch_data', params: { name: utils.getSynagogueName()}});
            analyticsSetUserProperties();
        };
        // var startTime = performance.now()
        // console.log(`##########start Call to doSomething took milliseconds`)


        fetchData().then(()=>{
            // var endTime = performance.now()
            // console.log(`##########Call to doSomething took ${endTime - startTime} milliseconds`)
            if(UPDATE_USER_STATS){
                utils.updateUserAction();
                UPDATE_USER_STATS = false;
            }
        });
    }, []);


    useEffect(() => {
        window.addEventListener("resize", ()=>{
            window.innerWidth >= 768 ? setPlasmaMode(true) : setPlasmaMode(false);
        });
    }, []);

    const getHebrewDatesList = async ()=>{

        const decrypt = ({string})=>{
            let newString = '';
            for(let i=0; i<string.length; i++ ){
                newString+=String.fromCharCode(string.charCodeAt(i) - 5);
            }
            return newString;
        }

        try {
            console.log('[getHebrewDatesList] fetching data...');
            const hebrewDatesListEncrypt = await store.getHebrewDates();
            console.log('[getHebrewDatesList] data fetched ',hebrewDatesListEncrypt);
            const hebrewDatesListDecrypt = JSON.parse(decrypt({string: hebrewDatesListEncrypt.result}));
            console.log('[getHebrewDatesList] data analyzed:  ',hebrewDatesListEncrypt);
            return hebrewDatesListDecrypt;
        }catch (e){
            console.error('got error while parsing getHebrewDates: ',e);
            return [];
        }
    }


    // console.log("----prayersHebrewDatesEvents:",prayersHebrewDatesEvents)

    if(plasmaMode){
        return <Plasma loading={loading} base64Logo={base64Logo}
                       prayersHebrewDatesEvents={prayersHebrewDatesEvents}
                       location={location} name={name} useYeshivaTimes={useYeshivaTimes}
                       plasmaSetting={plasmaSetting} updates={updates} menuOpen={menuOpen} times={times} shabatEnterMinBeforeSunset={shabatEnterMinBeforeSunset} shabatExitMinAfterStarsCameOut={shabatExitMinAfterStarsCameOut}/>
    }

    if(loading) return <Loading/>

    if(history.location.hash === '#signup') return <SignUp/>

    document.title = name;


    return <div className={'main-v2-container'}>
        <FirstOnBoarding/>
        <div className={'top-main-container'}>
            <NextShbbat loading={loading} location={location} useYeshivaTimes={useYeshivaTimes} shabatEnterMinBeforeSunset={shabatEnterMinBeforeSunset}/>
            <SmartNotifications loading={loading} location={location}/>
        </div>
        <Calander loading={loading} location={location}/>
        <FloatButtonDonation loading={loading} donationObj={donationObj}/>
        <NewsAndUpdatesSlider loading={loading} location={location} updates={updates}
                              externalFormDetails={externalFormDetails} externalFormUrl={externalFormUrl} prayersHebrewDatesEvents={prayersHebrewDatesEvents}/>
        <DailyStudies/>
        <DvarTora loading={loading} dvarTora={dvarTora} tvarToraYoutubeLink={tvarToraYoutubeLink}/>
        <SpecialMessages loading={loading}/>
        <UnionOfSynagoguesLinkButton loading={loading}/>
        <BottomButtons loading={loading}/>
    </div>
}

export default Main;