import React, {useState, useEffect} from 'react';
import TextViewer from "../../../../../helpers/textViewer";
import '../../PrayersDetails/PrayerDetails/PrayerDetails.scss';
import TIME_CONFIG from "../config/timeConfig";
import utils from '../../../../../helpers/utils';
import {message, Select} from "antd";

message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});
const { getTimesStatics } = utils;

const RELATIVE_TIME_BY = {
    ENTER_SHABAT: "כניסת שבת",
    EXIT_SHABAT: "יציאת שבת",
    SUNRISE: "זריחה",
    SUNSET: "שקיעה",
    TZEIT: "צאת הכוכבים",
    PLAG_MINHA: "פלג המנחה"
};

const Time = (props)=>{

    const { times, index, close, updateData, cloneMode, holidayMode = false } = props;
    let itemToRender = {};

    const editMode = (props.index !== -1) && (index !== -1);
    if(editMode){
        itemToRender = times[index];
    }

    const [itemToSave, setItemToSave] = useState({...itemToRender});
    const [isDynamic, setIsDynamic] = useState(itemToRender.isDynamic);

    // console.log("AliyahPanel: ",{times, index, itemToRender, itemToSave, editMode});

    // useEffect(() => {
    //     console.log("itemToSave: ", {itemToSave, isDynamic});
    //
    // }, [itemToSave, isDynamic]);


    const renderField = (key)=>{

        const {keyName, text_view_name, default: defaultVal, placeholder = '', multipleSelect = false, dynamicKey = false, viewOptions, defaultOption, holiday, regular, shoyMinyanList, placeholderCallBack } = TIME_CONFIG[key];


        if(holiday && !holidayMode){
            return;
        }

        if(regular && holidayMode){
            return;
        }

        if(dynamicKey && !isDynamic){
            return;
        }

        if((defaultVal) && !itemToSave[keyName]) {
            setItemToSave({...itemToSave, [keyName]: defaultVal});
        }

        const onMultiSelectChange = (array)=>{
            setItemToSave({...itemToSave, [keyName]: array});
        }

        const onCheckboxChange = (event)=>{
            if(keyName === 'isDynamic'){
                setIsDynamic(event.target.checked);
            }
            setItemToSave({...itemToSave, [keyName]: event.target.checked});
        }

        const onTextInputChange = (event, validator)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            try{
                JSON.parse(event.target.value);
                setItemToSave({...itemToSave, [keyName]: (event.target.value)});
            }catch (e){
                setItemToSave({...itemToSave, [keyName]: (event.target.value)});
            }
        }


        let input;
        switch (TIME_CONFIG[key].type) {
            case 'checkbox':
                input = <input type={'checkbox'} onChange={onCheckboxChange} checked={itemToSave[key]}/>
                break;
            case 'time':
                input = <input type={'time'} onChange={onTextInputChange} value={itemToSave[key]} min={"00:00"} max={"23:59"} required disabled={key === 'when' && isDynamic}/>
                break;
            case 'select':
                if(multipleSelect){
                    input = <Select onChange={onMultiSelectChange} mode={"multiple"}
                                    showArrow
                                    showSearch={false}
                                    value={itemToSave[key]}
                                    options={TIME_CONFIG[key].options.map(option=>({label: TextViewer({text: option, returnString: true}), value: option}))}/>
                }else{
                    input = <select onChange={onTextInputChange} value={(typeof itemToSave[key] === 'string' || Array.isArray(itemToSave[key]))  ? itemToSave[key] : JSON.stringify(itemToSave[key])} multiple={multipleSelect}>
                        {[defaultOption ? <option value={''} selected>{TextViewer({text: defaultOption, returnString: true})}</option> : null].concat(TIME_CONFIG[key].options.map(option=><option value={typeof option === 'string' ? option : JSON.stringify(option)}>{TextViewer({text: RELATIVE_TIME_BY[option] ? RELATIVE_TIME_BY[option] : (viewOptions ? viewOptions(option) : option), returnString: true})}</option>))}
                    </select>
                }

                break;
            default:
                input = <input onChange={onTextInputChange} value={itemToSave[key]} placeholder={placeholderCallBack ? placeholderCallBack((getTimesStatics(times).minyanNames.length > 1 ? getTimesStatics(times).minyanNames : [])) : TextViewer({text: placeholder, returnString: true})}/>
        }

        return <div className={'filed-container'}>
            <TextViewer text={text_view_name} addChar={TIME_CONFIG[key].required ? '* ' : ''} addBefore/>
            {input}
        </div>
    }

    const isEnabledToSaved = ()=>{
        const hasChanged =  JSON.stringify(itemToRender) !== JSON.stringify(itemToSave);
        let allRequiredFields = true;
        Object.keys(TIME_CONFIG).forEach(key=>{
            if(key === 'user'){
                if(index === -1) allRequiredFields = false;
                else return;
            }
            if(key === 'importantDates' && !holidayMode) return;
            if(key === 'days' && holidayMode) return;
            if(TIME_CONFIG[key].required && (!itemToSave[key] && itemToSave[key] !== 0)){
                allRequiredFields = false;
            }
            if(TIME_CONFIG[key].required && Array.isArray(itemToSave[key]) && itemToSave[key].length === 0 ){
                allRequiredFields = false;
            }
        });
        return hasChanged && allRequiredFields;
    }

    const onSaveClicked = ()=>{
        const updatedItems = [...times];
        const copyOfItemToSave = {...itemToSave};
        if(copyOfItemToSave.importantDates && typeof copyOfItemToSave.importantDates === 'string') copyOfItemToSave.importantDates = JSON.parse(copyOfItemToSave.importantDates)

        if(editMode){
            if(cloneMode){
                updatedItems.push(copyOfItemToSave);
            }else{
                updatedItems[index] = copyOfItemToSave;
            }
        }else{
            updatedItems.push(copyOfItemToSave);
        }

        updateData({fieldName: 'times', data: updatedItems});
        close();
        message.open({
            type: 'success',
            content: TextViewer({text: 'saved', returnString: true}),
        })
    }

    return <div className={'item-details-container'}>
        <div className={'title'}>
            <TextViewer text={'add_edit_event'}/>
        </div>
        <div className={'body'}>
            {Object.keys(TIME_CONFIG).map((key)=>renderField(key))}
        </div>
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={onSaveClicked}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=>close()}><TextViewer text={'return'}/></button>
        </div>
    </div>
}

export default Time;