import React, {useState, useEffect} from 'react';
import './PrayerDetails.scss';
import TextViewer from "../../../../../helpers/textViewer";
import PRAYER_CONFIGS from '../config/prayerConfig';
import {useHistory} from "react-router-dom";
import UploadAttachment from "../../UploadAttachment/uploadAttachments";
import {message} from "antd";
import utils from "../../../../../helpers/utils";
message.config({
    top: '90%',
    duration: 2,
    maxCount: 3,
    rtl: true
});
const createDefaultItem = ()=>{
    const newEmptyItem = {};
    Object.keys(PRAYER_CONFIGS).forEach(key=>{
        newEmptyItem[key] = PRAYER_CONFIGS[key].default;
    });
    return newEmptyItem;
}

const addDefaultToExistingItem = (item)=>{
    const existingItem = {...item};
    Object.keys(PRAYER_CONFIGS).forEach(key=>{
        if(PRAYER_CONFIGS[key].default && !existingItem[key]){
            existingItem[key] = PRAYER_CONFIGS[key].default;
        }
    });
    return existingItem;
}

const PrayerDetails = (props)=>{
    const history = useHistory();

    const { itemsToRender, close, updateData, userMode = false } = props;
    const creationMode = props.itemToRender === null;

    const itemToRender = creationMode ? createDefaultItem() : addDefaultToExistingItem(props.itemToRender)

    const [itemToSave, setItemToSave] = useState(JSON.parse(JSON.stringify(itemToRender)));
    const [deleteDeptAmount, setDeleteDeptAmount] = useState(0);
    const [saveMsgWarn, setSaveMsgWarn] = useState('');

    useEffect(() => {
       console.log({ itemsToRender, close, updateData, userMode, creationMode, itemToRender, itemToSave, saveMsgWarn, deleteDeptAmount})
    }, [ itemsToRender, close, updateData, userMode, creationMode, itemToRender, itemToSave, saveMsgWarn, deleteDeptAmount]);

    useEffect(() => {
        let enable = true;
        let requiredField = '';
        Object.values(PRAYER_CONFIGS).forEach(config=>{
            if(config.required && !itemToSave[config.keyName]){
                enable = false;
                if(!requiredField){
                    requiredField = TextViewer({text: config.text_view_name, returnString: true});
                    requiredField = TextViewer({text: 'please_fill_the_filed', returnString: true, addedString: requiredField});
                }
            }
            if(typeof config.validation === 'function' && !config.validation(itemToSave[config.keyName])){
                enable = false;
                if(!requiredField){
                    requiredField = TextViewer({text: config.text_view_name, returnString: true});
                    requiredField = TextViewer({text: 'please_fill_the_filed', returnString: true, addedString: requiredField});
                }
            }
        })
        setSaveMsgWarn(requiredField);
    }, [itemToSave]);

    const renderArrayOfObjects = ({keyName, objectStructure, text_view_button_name})=>{
        const onArrayChange = ({event, index, key})=>{
            const newItemToSave = JSON.parse(JSON.stringify(itemToSave)); //deep copy
            newItemToSave[keyName][index][key] = event.target.value;
            setItemToSave(newItemToSave);
        }

        const onArrayDateChange = ({event, index, key})=>{
            const newItemToSave = JSON.parse(JSON.stringify(itemToSave)); //deep copy
            newItemToSave[keyName][index][key] = (new Date(event.target.value)).toJSON();
            setItemToSave(newItemToSave);
        }

        const addItem = ()=>{
            const newItemToSave = JSON.parse(JSON.stringify(itemToSave)); //deep copy
            const newEmptyItem = {};
            Object.keys(objectStructure).forEach(key=>{
                if(objectStructure[key].default){
                    newEmptyItem[key] = objectStructure[key].default;
                }
            })
            if(!Array.isArray( newItemToSave[keyName])) newItemToSave[keyName] = [];
            newItemToSave[keyName].push(newEmptyItem)
            setItemToSave(newItemToSave);
        }

        const deleteItem = (index)=>{
            const newItemToSave = JSON.parse(JSON.stringify(itemToSave)); //deep copy
            delete newItemToSave[keyName][index];
            newItemToSave[keyName] = newItemToSave[keyName].filter(a=>a);
            setItemToSave(newItemToSave);
        }

        const arrayOfObject = itemToSave[keyName] || [];
        if(!Array.isArray(arrayOfObject)) return;
        return arrayOfObject.map((item, index)=>{
            return <div className={'object-to-render'}>
                {Object.keys(objectStructure).sort((key1, key2)=>objectStructure[key1].order - objectStructure[key2].order).map(key=>{
                    if(objectStructure[key].type === 'viewOnly'){
                        return <span><TextViewer text={objectStructure[key].text_view_only} addChar={':'}/></span>
                    }
                    if(objectStructure[key].type === 'selectDate'){
                        return <input type={'date'} max={new Date().toISOString().substr(0, 10)} onChange={(event)=>onArrayDateChange({event, index, key})}
                               value={item[key] ? new Date(item[key]).toISOString().substr(0, 10) : null}/>
                    }
                    if(typeof objectStructure[key].type === 'string'){
                        return <input value={item[key]}
                                      onChange={(event)=>onArrayChange({event, index, key})}
                                      placeholder={TextViewer({text: objectStructure[key].text_view_name, returnString: true})}/>
                    }else if(Array.isArray(objectStructure[key].type)){
                        return <select value={item[key]} onChange={(event)=>onArrayChange({event, index, key})}>
                            {objectStructure[key].type.map(option=><option value={option}>{option}</option>)}
                        </select>
                    }
                })}
                <div className={'delete-object-item'}>
                    <button onClick={()=>deleteItem(index)}><TextViewer text={'delete'}/></button>
                </div>
            </div>
        }).concat([
            <div className={'add-object-item'}>
                <button onClick={addItem}><TextViewer text={text_view_button_name}/></button>
            </div>
        ])
    }



    const renderField = (key)=>{

        const {keyName, text_view_name, base64Mode, text_view_button_name } = PRAYER_CONFIGS[key];

        const onTextInputChange = (event, validator)=>{
            if(validator){
                if(!validator(event.target.value)) return;
            }
            setItemToSave({...itemToSave, [keyName]: event.target.value});
        }

        const onUploadFileChange = (data)=>{
            setItemToSave({...itemToSave, [keyName] : data})
        }

        const onCheckboxChange = (event)=>{
            setItemToSave({...itemToSave, [keyName]: event.target.checked});
        }

        if(PRAYER_CONFIGS[key].adminViewOnly && userMode) return;

        let input;
        switch (PRAYER_CONFIGS[key].type) {
            case 'select':
                input = <select onChange={onTextInputChange} value={itemToSave[key]}>
                    {PRAYER_CONFIGS[key].options.map(option=><option value={option}>{option}</option>)}
                </select>
                break;
            case 'uploadFile':
                input = <UploadAttachment onTextChange={onUploadFileChange} attachmentUrl={itemToSave[key]} base64Mode={base64Mode}/>
                break;
            case 'tel':
                input = <input type={"tel"} placeholder={"0541234567"} onChange={(event)=>onTextInputChange(event, (num)=>num.length <= 10 && !isNaN(num))} value={itemToSave[key]}/>
                break;
            case 'arrayOfObjects':
                input = renderArrayOfObjects({keyName, objectStructure: PRAYER_CONFIGS[key].objectStructure, text_view_button_name})
                break;
            case 'checkbox':
                input = <input type={'checkbox'} onChange={onCheckboxChange} checked={itemToSave[key]}/>
                break;
            default:
                input = <input onChange={onTextInputChange} value={itemToSave[key]}/>
        }

        return <div className={'filed-container'}>
            <TextViewer text={text_view_name} addChar={PRAYER_CONFIGS[key].required ? '* ' : ''} addBefore/>
            {input}
        </div>
    }


    const onSaveClicked = (forceItemToSaved)=>{
        const updatedItems = [...itemsToRender];
        if(creationMode){
            updatedItems.push(itemToSave);
        }else{
            const indexToUpdate = updatedItems.findIndex(item=>item.name === itemToRender.name && item.family === itemToRender.family && item.phoneNumber === itemToRender.phoneNumber );
            if(indexToUpdate === -1) console.error("can't find item to update! itemToSave: ", {itemToRender, itemToSave});
            updatedItems[indexToUpdate] = forceItemToSaved ? forceItemToSaved : itemToSave;
        }
        updateData({fieldName: 'manager', data: updatedItems});
        close();
        message.open({
            type: 'success',
            content: TextViewer({text: 'prayer_card_have_updated', returnString: true}),
        })
    }

    const isEnabledToSaved = ()=>{
        if(userMode && !localStorage.getItem("UPDATE_PRAYER")){
            return true;
        }
        const hasChanged =  JSON.stringify(itemToRender) !== JSON.stringify(itemToSave);
        let allRequiredFields = true;
        Object.keys(PRAYER_CONFIGS).forEach(key=>{
            if(PRAYER_CONFIGS[key].required && !itemToSave[key]){
                allRequiredFields = false;
            }
        });
        return hasChanged && allRequiredFields &&!saveMsgWarn;
    }

    const calcDept = ()=>{
        let amount = 0;
        if(Array.isArray(itemToRender.aliyot)){
            itemToRender.aliyot.forEach(aliyah=>{
                if(aliyah?.isPayed === true) return;
                if(aliyah.aliyahPayment) amount+= Number(aliyah.aliyahPayment);
                if(aliyah.aliyahSubPayment) amount-= Number(aliyah.aliyahSubPayment);
            })
        }
        if(Array.isArray(itemToRender.donations)){
            itemToRender.donations.forEach(donation=>{
                if(donation?.isPayed === true) return;
                if(donation.donationTotalPayment) amount+= Number(donation.donationTotalPayment);
                if(donation.donationSubPayment) amount-= Number(donation.donationSubPayment);
            })
        }
        return amount;
    }

    const dept = calcDept();

    const makeOffset = ()=>{
        if(!deleteDeptAmount) return;
        const copyOfItemToSave = JSON.parse(JSON.stringify(itemToSave));

        let copyOfDeleteDeptAmount = deleteDeptAmount;

        while (copyOfDeleteDeptAmount > 0){
            if(Array.isArray(itemToSave.aliyot)){
                copyOfItemToSave.aliyot.forEach(aliyah=>{
                    if(aliyah.isPayed === true) return;
                    if(aliyah.aliyahSubPayment) aliyah.aliyahSubPayment = Number(aliyah.aliyahSubPayment);
                    if(aliyah.aliyahPayment) aliyah.aliyahPayment = Number(aliyah.aliyahPayment);
                    if(aliyah.aliyahSubPayment){
                        if((aliyah.aliyahPayment - aliyah.aliyahSubPayment) < copyOfDeleteDeptAmount){
                            aliyah.aliyahSubPayment = 0;
                            aliyah.isPayed = true;
                            copyOfDeleteDeptAmount = copyOfDeleteDeptAmount - (aliyah.aliyahPayment - aliyah.aliyahSubPayment)
                        }else{
                            aliyah.aliyahSubPayment = aliyah.aliyahSubPayment + copyOfDeleteDeptAmount;
                            copyOfDeleteDeptAmount = 0;
                        }
                    }else{
                        if(aliyah.aliyahPayment < copyOfDeleteDeptAmount){
                            aliyah.isPayed = true;
                            copyOfDeleteDeptAmount = copyOfDeleteDeptAmount - aliyah.aliyahPayment;
                        }else{
                            aliyah.aliyahSubPayment = copyOfDeleteDeptAmount;
                            copyOfDeleteDeptAmount = 0;
                        }
                    }
                })
            }

            if(Array.isArray(copyOfItemToSave.donations)){
                copyOfItemToSave.donations.forEach(donation=>{
                    if(donation.isPayed === true) return;
                    if(donation.donationSubPayment) donation.donationSubPayment = Number(donation.donationSubPayment);
                    if(donation.donationTotalPayment) donation.donationTotalPayment = Number(donation.donationTotalPayment);
                    if(donation.donationSubPayment){
                        if((donation.donationTotalPayment - donation.donationSubPayment) < copyOfDeleteDeptAmount){
                            donation.donationSubPayment = 0;
                            donation.isPayed = true;
                            copyOfDeleteDeptAmount = copyOfDeleteDeptAmount - (donation.donationTotalPayment - donation.donationSubPayment)
                        }else{
                            donation.donationSubPayment = donation.donationSubPayment + copyOfDeleteDeptAmount;
                            copyOfDeleteDeptAmount = 0;
                        }
                    }else{
                        if(donation.donationTotalPayment < copyOfDeleteDeptAmount){
                            donation.isPayed = true;
                            copyOfDeleteDeptAmount = copyOfDeleteDeptAmount - donation.donationTotalPayment;
                        }else{
                            donation.donationSubPayment = copyOfDeleteDeptAmount;
                            copyOfDeleteDeptAmount = 0;
                        }
                    }
                })
            }
        }

        onSaveClicked(copyOfItemToSave)

    }

    return <div className={'item-details-container'}>
        {!userMode ? <div className={'title'}>
            <TextViewer text={'add_edit_prayer'}/>
        </div> : null}
        <div className={'body'}>
            {Object.keys(PRAYER_CONFIGS).map((key)=>renderField(key))}
        </div>
        {!userMode ? <React.Fragment>
            <div className={'donation-dept-container'}>
                <TextViewer text={'total_dept'} addChar={String(dept)} addedString={'ש״ח'}/>
                {<button disabled={!dept} onClick={()=>{
                    let msg = `שלום `;
                    msg+= itemToRender.name;
                    msg+= ' !';
                    msg+= '%0A';
                    msg+= 'קיים חוב בסך: ';
                    msg+= dept;
                    msg+= ' שקלים.';
                    msg+= '%0A';
                    msg+= 'ניתן לשלם כאן:';
                    msg+= '%0A';
                    msg+= `${window.location.origin}/${window.location.pathname.split("/")[1]}/donation_v2`

                    utils.sendWhatsAppMessage({msg, phoneNumber: itemToRender.phoneNumber})
                }}><TextViewer text={'donation_reminder'}/></button>}
            </div>
            <div className={'offset-dept'}>
                <TextViewer text={'offset_dept'} addChar={':'}/>
                <input type={'number'} min={1} max={dept} value={deleteDeptAmount} onChange={(event)=>{
                    const value = event.target.value;
                    if(value < 0 || value > dept) return;
                    setDeleteDeptAmount(event.target.value);
                }}/>
                <TextViewer text={'ש״ח'} />
                <button disabled={deleteDeptAmount < 1} onClick={makeOffset}><TextViewer text={'offset'}/></button>
            </div>
            <div>
                <a onClick={()=>{
                    history.push({
                        hash: 'aliyah_panel',
                        search: `search=${itemToRender.name} ${itemToRender.family}`
                    });
                }}><TextViewer text={'view_all_aliyot'}/></a>
            </div>
            <div>
                <a onClick={()=>{
                    history.push({
                        hash: 'donations',
                        search: `search=${itemToRender.name} ${itemToRender.family}`
                    });
                }}><TextViewer text={'view_all_donations'}/></a>
            </div>
        </React.Fragment> :
            <div className={'donation-dept-container'}>
                <TextViewer text={'total_dept'} addChar={String(dept)} addedString={'ש״ח'}/>
            </div>
        }
        {saveMsgWarn ? <div className={'save-msg-warning-container'}>
            <TextViewer text={saveMsgWarn} addChar={' * '} addBefore/>
        </div> : null}
        <div className={'bottom-buttons'}>
            <button className={'bottom-buttons-save'} disabled={!isEnabledToSaved()} onClick={()=>userMode ? props.onSaveClicked({itemToSave}) : onSaveClicked(false)}>{<TextViewer text={'save'}/>}</button>
            <button className={'bottom-buttons-back'} onClick={()=> userMode ? props.onCloseClicked() : close()}><TextViewer text={'return'}/></button>
        </div>
    </div>
}

export default PrayerDetails;